import Footer from "./Footer"
export default function AboutUs () {
    return (
        <>
 <div className="bg-black px-5 lg:px-20">
    <div className="max-w-6xl static mx-auto">
    <section aria-labelledby="features-heading" className="relative">
      <div className="aspect-h-2 aspect-w-3 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-16 pt-7">
      <img
          src={require("./pictures/markandjosh.jpg")}
          alt="Big Zeronimo Red"
        />
      </div>

      <div className="mx-auto max-w-2xl md:px-4 sm:px-2 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 ">
        <div className="md:col-start-2">
          <br className="md:hidden"></br>
          <p className="mt-4 text-white didact-gothic-regular text-xl">
          Joshua James opened Ocean Beach Cafe in 2021 to bring awareness and accessibility to the non alcoholic (NA) beverage market.
          Building on the success of Ocean Beach Cafe, SF’s original non-alcoholic bar and bottle shop,
          Ocean Beach Brands is the place to connect with the best NA products in this new market.
</p>
<p className="mt-4 text-white didact-gothic-regular text-xl">
From AF wines to adaptogenic cocktails,
Ocean Beach Brands is here to help you navigate this wonderful world of alternative beverages
so you can make an informed decision about what’s good right now.
 </p>
<p className="mt-4 text-white didact-gothic-regular text-xl">
The Ocean Beach Brands team is here to help.
We have the experience, and the data, to help guide bar managers, venues,
and brands to the hottest NA products that their customers are asking for.
</p>
        </div>
      </div>
    </section>


    </div>
  </div>
        <Footer />

        </>
    )
}
