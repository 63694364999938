import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useState } from "react";

const MailchimpFormContainer = props => {
    const postUrl = 'https://oceanbeachbrands.us17.list-manage.com/subscribe/post?u=13eaa9e01f079bd91576b2bf5&id=dd4d9d5aea';


    return (
    <div className="mc__form-container">
    <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message}) => (
            <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
                />
        )}
        />
    </div>
  );
};

const CustomForm = ({ status, message, onValidated }) => {
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);

    function handleInput(event) {
        setEmail(event.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (email === "") {
            setIsEmailValid(false)
        } else {
            setIsEmailValid(true);
            console.log(email)
            onValidated({EMAIL: email})
            console.log("merged")
            alert("Thank you for subscribing!");
            setEmail("");
            console.log("email back to nothing")
        };
    };

    return (
        <div className="bg-black pb-5 lg:py-16">
        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
          <div className="max-w-xl text-3xl font-bold  text-cursive didact-gothic-regular tracking-tight text-white sm:text-4xl lg:col-span-7">
            <h2 className="inline sm:block lg:inline xl:block">Want product news and updates? </h2>
            <p className="inline sm:block lg:inline xl:block">Sign up for our newsletter.</p>
          </div>
          {status === "error" && (
            <div>Something went wrong. Please try again.</div>
          )}
         {status === "success" && (
            <div className="text-white">Thanks for signing up!</div>
          )}
          <form className="w-full max-w-md lg:col-span-5" onSubmit={handleSubmit}>
            <div className="flex gap-x-4">
              <label htmlFor="email-address" className="sr-only">Email address</label>
              <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required=""
              className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              placeholder="Enter your email"
              value={email}
              onChange={handleInput}/>
              <button
              type="submit"
              className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                Subscribe
                </button>
            </div>
          </form>
        </div>
      </div>
    );
};



export default MailchimpFormContainer;
