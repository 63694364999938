import { Link } from "react-router-dom";

export default function NavBar() {
  return (
    <nav className="bg-black border-black dark:bg-black dark:black">
      <div className="block w-full md:hidden">
        <ul className="flex md:flex-row font-medium p-4 p-0 md:space-x-8 md:mt-0">
          <li>
            <Link to="/aboutus" className="py-2 md:pl-3 md:pr-4 text-gray-900 rounded-md:border-0 hover:text-blue-200 md:p-0 dark:text-white didact-gothic-regular">ABOUT US</Link>
          </li>
          <li>
            <Link to="https://oceanbeachbrands.myshopify.com" className="py-2 pl-3 md:pr-4 text-gray-900 hover:text-blue-200 md:border-0 dark:text-white didact-gothic-regular">SHOP</Link>
          </li>
          <li>
            <Link to="https://oceanbeachbrands.medium.com/" className="py-2 pl-3 md:pr-4 text-gray-900 hover:text-blue-200 md:border-0 dark:text-white didact-gothic-regular">BLOG</Link>
          </li>
        </ul>
      </div>
      <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4">
        <Link to="/" className="flex items-center">
          <span className="flex items-center justify-center self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            <img className="h-10" src={require("./pictures/logo.png")} alt="Logo" />
            <img
              className="h-20 ml-8"
              src={require("./pictures/zeronimo_logo.png")}
              alt="Zeronimo Logo"
            />
          </span>
        </Link>
        <div className="hidden md:block">
          <ul className="flex md:flex-row font-medium p-4 p-0 mt-4 md:space-x-8 md:mt-0">
            <li>
              <Link to="/aboutus" className="py-2 md:pl-3 md:pr-4 text-gray-900 rounded-md:border-0 hover:text-blue-200 md:p-0 dark:text-white didact-gothic-regular">ABOUT US</Link>
            </li>
            <li>
              <Link to="https://oceanbeachbrands.myshopify.com" className="py-2 pl-3 md:pr-4 text-gray-900 hover:text-blue-200 md:border-0 dark:text-white didact-gothic-regular">SHOP</Link>
            </li>
            <li>
              <Link to="https://oceanbeachbrands.medium.com/" className="py-2 pl-3 md:pr-4 text-gray-900 hover:text-blue-200 md:border-0 dark:text-white didact-gothic-regular">BLOG</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
