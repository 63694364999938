export default function Review() {
    return (
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t lg:mx-0 lg:max-w-none lg:grid-cols-3 pt-20">
                <div class="group relative">
                    <h3 class="text-white text-center text-cursive didact-gothic-regular text-xl lg:mx-5">“Leonis Blend is an excellent wine with outstanding flavor persistence. It has an herbaceous
aroma that is reminiscent of a Cabernet Franc, is dry, and well-balanced. It's great to see such
high-quality wines entering the non-alcoholic category.”
</h3>
                    <p class="line-clamp-3 text-sm leading-6 mt-5 text-center text-cursive didact-gothic-regular text-gray-200">Morgan Robinson, Co-Founder & CEO at Southside Napa</p>
                </div>
                <div class="group relative">
                    <h3 class="text-white text-center text-cursive didact-gothic-regular text-xl lg:mx-5">“This wine is great! I have tried a lot of non alcoholic wines and this is by far the best, especially in regards to aroma and taste. I highly recommend this bottle!”  </h3>
                    <p class="line-clamp-3 text-sm leading-6 mt-5 text-center text-cursive didact-gothic-regular text-gray-200">Wendy, NYC resident</p>
                </div>
                <div class="group relative">
                    <h3 class="text-white text-center text-cursive didact-gothic-regular text-xl lg:mx-5">“Recently, I had the pleasure of tasting the Leonis Blend, which was so reminiscent of an old-world style from Uruguay. Wine, like food, has to start with good ingredients (grapes) followed by the right technique or style of production. Leonis Blend is that wine and is sure to satisfy the cravings of red wine lovers.” </h3>
                    <p class="line-clamp-3 text-sm leading-6 mt-5 text-center text-cursive didact-gothic-regular text-gray-200">Irma Robinson, Co-Founder & COO at Southside Napa</p>
                </div>
            </div>
            <div className="border-b">
            <div class="mx-auto grid mb-10 max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mt-5 lg:mx-10 lg:max-w-none lg:grid-cols-2">
                <div class="group relative">
                    <h3 class="text-white text-center text-cursive didact-gothic-regular text-xl lg:mx-15 mt-20 md:mt-18 lg:mt-0">“Easily the best alcohol-removed red wine available in the US.” </h3>
                    <p class="line-clamp-3 text-sm leading-6 mt-5 text-center text-cursive didact-gothic-regular text-gray-200">Guillaume Lelait, SF resident & Dad</p>
                </div>
                <div class="group relative">
                    <h3 class="text-white text-center text-cursive didact-gothic-regular text-xl lg:mx-5">“I’ve tried nearly every NA wine on the market, and frankly… it's all disappointing. Leonis Blend is the exception, it's big and it's funky. Leonis Blend isnt just “good for NA wine”, its just fucking good.”</h3>
                    <p class="line-clamp-3 text-sm leading-6 mt-5 text-center text-cursive didact-gothic-regular text-gray-200">Jon Lerner, Co-Founder at Good Spirits</p>
                </div>
                </div>
            </div>
        </div>
    )
}
