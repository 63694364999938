import {useState} from 'react';
import MailchimpFormContainer from './MailChimp';


export default function Footer () {

    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);

    function handleInput(event) {
        setEmail(event.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (email === "") {
            setIsEmailValid(false)
        } else {
            setIsEmailValid(true);
            console.log(email)
            alert("Thank you for subscribing!");
            setEmail("");
        }
    }
    return (
        <footer className="w-full h-64 bg-black static bottom-0 pt-10">
          <MailchimpFormContainer />
  <p className="px-6 text-gray-500 font-serif text-left bg-black">© Ocean Beach Brands</p>
</footer>
    );
}
