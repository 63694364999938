import Footer from "./Footer";
import { Link } from "react-router-dom";
import Review from "./Reviews";

const centuryBlend = {
  name: 'Zeronimo Century Blend',
  price: '$220',
  rating: 3.9,
  href: 'https://www.zeronimowine.com/century-blend-reservation/',
  description:
    'Following its much-anticipated debut in late September, ZERONIMO has made history by officially launching the world’s first 20-year-old dealcoholized red wine, Century Blend, in Europe. This exceptional creation is a rarity, available in a limited edition, and showcases the extraordinary aromas and complexity that only decades of maturation can achieve. Now for the first time, it can be enjoyed completely alcohol-free.',
  imageSrc: 'https://cdn.zeronimowine.com/wp-content/uploads/2024/11/2024-zeronimo-century-blend-image-01-1536x1073.jpg',
  imageAlt: 'Back angled view with bag open and handles to the side.',
  colors: [
    { name: 'Washed Black', bgColor: 'bg-gray-700', selectedColor: 'ring-gray-700' },
    { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
    { name: 'Washed Gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500' },
  ],
}

const products = [
  {
    id: 1,
    name: 'Zeronimo Zweigelt',
    price: '$220',
    rating: 3.9,
    href: 'https://oceanbeachbrands.myshopify.com/products/zweigelt-red',
    description:
      'A smooth, full-bodied red with flavors of blackberry, cherry, vanilla, and hints of exotic wood spices.',
    imageSrc: 'https://cdn.zeronimowine.com/wp-content/uploads/2024/09/flaschengrosse-zeronimowinecom-609-x-2327-px-1-536x2048.png',
    imageAlt: 'Back angled view with bag open and handles to the side.',
    colors: [
      { name: 'Washed Black', bgColor: 'bg-gray-700', selectedColor: 'ring-gray-700' },
      { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
      { name: 'Washed Gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500' },
    ],
  },
  {
    id: 2,
    name: 'Zeronimo Grüner Veltliner',
    price: '$220',
    rating: 3.9,
    href: 'https://oceanbeachbrands.myshopify.com/products/gruner-veltliner',
    description:
      'A crisp white wine with green apple, peach, and lemon notes, balanced by vibrant minerality.',
    imageSrc: 'https://cdn.zeronimowine.com/wp-content/uploads/2024/09/flaschengrosse-zeronimowinecom-609-x-2327-px-536x2048.png',
    imageAlt: 'Back angled view with bag open and handles to the side.',
    colors: [
      { name: 'Washed Black', bgColor: 'bg-gray-700', selectedColor: 'ring-gray-700' },
      { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
      { name: 'Washed Gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500' },
    ],
  },
  {
    id: 3,
    name: 'Zeronimo Leonis Blend',
    price: '$220',
    rating: 3.9,
    href: 'https://oceanbeachbrands.myshopify.com/products/leonis-blend',
    description:
      ' A rich, old-world red with notes of dark cherry, forest floor, and oak spice, crafted from a 98-point-rated Austrian blend aged in French oak.',
    imageSrc: 'https://cdn.zeronimowine.com/wp-content/uploads/2024/05/zeronimo-leonis-blend-2024-alcohol-free-red-wine-bottle-536x2048.png',
    imageAlt: 'Back angled view with bag open and handles to the side.',
    colors: [
      { name: 'Washed Black', bgColor: 'bg-gray-700', selectedColor: 'ring-gray-700' },
      { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
      { name: 'Washed Gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500' },
    ],
  },
  {
    id: 4,
    name: 'Zeronimo Sparkling Select',
    price: '$220',
    rating: 3.9,
    href: 'https://oceanbeachbrands.myshopify.com/products/sparkling-select',
    description:
      'A dry, elegant sparkling wine with aromas of roasted almonds, yellow fruits, and brioche, delivering a refined mineral finish.',
    imageSrc: 'https://cdn.zeronimowine.com/wp-content/uploads/2024/05/zeronimo-sparkling-select-2024-alcohol-free-sparkling-wine-bottle-536x2048.png',
    imageAlt: 'Back angled view with bag open and handles to the side.',
    colors: [
      { name: 'Washed Black', bgColor: 'bg-gray-700', selectedColor: 'ring-gray-700' },
      { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
      { name: 'Washed Gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500' },
    ],
  }
  // More items...
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function LandingPage() {
  return (
    <div className="bg-black min-h-screen">


<div className="overflow-hidden bg-black shadow sm:rounded-md">

  <div className="flex items-center justify-center">
    <div className="mt-8 px-4 sm:px-6 grid w-full grid-cols-1 items-center justify-center gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8 max-w-[1200px]">
      <div className="flex justify-center px-8 sm:col-span-4 lg:col-span-5">
        <img
          alt={centuryBlend.imageAlt}
          src={centuryBlend.imageSrc}
          className="h-[400px] object-cover rounded-md"
        />
      </div>
      <div className="sm:col-span-8 lg:col-span-7">
        <h2 className="text-3xl font-bold text-gray-200 sm:pr-12"><span className="text-3xl text-yellow-500">New Arrival! </span>{centuryBlend.name}</h2>

        <section aria-labelledby="information-heading" className="mt-3">
          <h3 id="information-heading" className="sr-only">
            Product information
          </h3>

          <p className="text-xl text-cursive didact-gothic-regular text-gray-300"><span className="font-bold">World's First</span> 20-Year Aged Dealcoholized Wine</p>

          <div className="mt-6">
            <h4 className="sr-only">Description</h4>

            <p className="text-cursive didact-gothic-regular text-gray-200">{centuryBlend.description}</p>
          </div>
        </section>

        <section aria-labelledby="options-heading" className="mt-6">
          <h3 id="options-heading" className="sr-only">
            Product options
          </h3>

          <form>
        
            <div className="mt-6 flex justify-center sm:justify-start">
            <button
              type="submit"
              onClick={() => window.open(centuryBlend.href, '_blank')}
              className="flex items-center justify-center rounded-md border border-transparent bg-yellow-500 px-8 py-3 text-base font-bold text-white hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
            >
              View Product
            </button>
            </div>

          </form>
        </section>
      </div>
    </div>
  </div>



  <ul role="list" className="border-t mt-8 grid grid-cols-1 md:grid-cols-2 divide-y md:divide-y-0 divide-gray-200">
    {products.map((product) => (
      <li key={product.id} className="px-4 py-4 sm:px-6">
                           
              <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                <div className="flex justify-center px-8 sm:col-span-4 lg:col-span-5">
                  <img
                    alt={product.imageAlt}
                    src={product.imageSrc}
                    className="h-[300px] object-cover"
                  />
                </div>
                <div className="sm:col-span-8 lg:col-span-7">
                  <h2 className="text-2xl font-bold text-gray-200 sm:pr-12">{product.name}</h2>

                  <section aria-labelledby="information-heading" className="mt-3">
                    <h3 id="information-heading" className="sr-only">
                      Product information
                    </h3>

                    {/* <p className="text-2xl text-gray-300">{product.price}</p> */}

                    <div className="mt-6">
                      <h4 className="sr-only">Description</h4>

                      <p className="text-lg text-gray-200 text-cursive didact-gothic-regular">{product.description}</p>
                    </div>
                  </section>

                  <section aria-labelledby="options-heading" className="mt-6">
                    <h3 id="options-heading" className="sr-only">
                      Product options
                    </h3>

                    <form>
                  
                      <div className="mt-6 w-full flex justify-center sm:justify-start">
                        <button
                          type="submit"
                          onClick={() => window.open(product.href, '_blank')}
                          className="flex items-center justify-center rounded-md border border-transparent bg-yellow-500 px-8 py-3 text-base font-bold text-white hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                        >
                          View Product
                        </button>
                      </div>

                    </form>
                  </section>
                </div>
              </div>

            </li>
          ))}
        </ul>
      </div>



        {/* <div className="md:hidden flex flex-col justify-center items-center">
          <img
            className="w-40 mb-4"
            src={require("./pictures/zeronimo_logo.png")}
            alt="Zeronimo Logo"
          />
          <p className="text-white text-center text-cursive didact-gothic-regular text-xl mx-5">
          In partnership with Ocean Beach Brands, Heribert Bayer proudly announces the US debut of ZERONIMO wine, the world’s first-ever dealcoholized 98 point rated wine.
          </p>
          <br />
          <Link to="http://zeronimowine.com">
            <p className="text-white hover:text-blue-200 text-center pb-8 didact-gothic-regular">
              Learn more about Zeronimo{" "}
            </p>
          </Link>
          <Link to="http://oceanbeachbrands.myshopify.com">
            <button className="animate-pulse bg-white text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
              Order now!
            </button>
          </Link>
          <button className="underline text-white hover:text-blue-200 text-center pt-8 didact-gothic-regular"
          onClick={() => window.location = "mailto:hi@oceanbeachbrands.com"}>
            Inquire about Wholesale here
            </button>
        </div> */}
      {/* <div className="grid grid-cols-2 md:grid-cols-3 px-10 md:max-w-5xl mx-auto">
        <img
          className="w-60"
          src={require("./pictures/Big Zeronimo Red.png")}
          alt="Big Zeronimo Red"
        />
        <img
          className="w-60"
          src={require("./pictures/Euro Sparkling Zeronimo Label.png")}
          alt="Euro Sparkling Zeronimo Label"
        />
        <div className="hidden md:flex md:flex-col md:justify-center md:items-center">
          <img
            className="w-40 mb-4"
            src={require("./pictures/zeronimo_logo.png")}
            alt="Zeronimo Logo"
          />
          <p className="text-white text-center text-cursive didact-gothic-regular text-xl">
          In partnership with Ocean Beach Brands, Austrian winemaker Heribert Bayer proudly announces the US debut of ZERONIMO wine, the first-ever 98 point rated wine to be dealcoholized.
          </p>
          <br />
          <Link to="http://zeronimowine.com">
            <p className="text-white hover:text-blue-200 text-center pb-10 didact-gothic-regular">
              Learn more about Zeronimo{" "}
            </p>
          </Link>
          <Link to="http://oceanbeachbrands.myshopify.com">
            <button className="animate-pulse bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
              Order now!
            </button>
          </Link>
          <button className="underline text-white hover:text-blue-200 text-center pt-10 didact-gothic-regular"
          onClick={() => window.location = "mailto:hi@oceanbeachbrands.com"}>
            Inquire about Wholesale here
            </button>
        </div>
      </div> */}
      <Review/>
      <Footer />
    </div>
  );
}
