
import './App.css';
import LandingPage from './Landing';
import AboutUs from './AboutUs';
import NavBar from './NavBar';
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import RedirectPage from './Redirect';
import RedirectBlog from './RedirectBlog';


function App() {
  return (
    <>
    <BrowserRouter className="bg-black">
    <NavBar/>
    <Routes>
      <Route
      path="/"
      element={<LandingPage/>}>
      </Route>
      <Route
      path="/aboutus"
      element={<AboutUs/>}>
      </Route>
      <Route
      path="/zeronimo"
      element={<RedirectPage/>}>
      </Route>
      <Route
      path="/blog"
      element={<RedirectBlog/>}>
      </Route>
      <Route
      path="/shop"
      element={<LandingPage/>}>
      </Route>
    </Routes>
    </BrowserRouter>
   </>
  );
}

export default App;
